import React, { useState, useEffect } from "react";

// mui components
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// custom elements
import ConsumptionChart from "./consumptionChart";
import FeedChart from "./feedChart";
import { getPeriodData } from "../utils/apiCalls";

function ChartDisplay(props) {
  if (props.outputValues[0] === 0 || props.outputValues[1] === 0) {
    return (
      <Container sx={{ textAlign: "center", py: 20 }}>
        <Typography color="text.disabled" sx={{ fontSize: 20 }}>
          keine Daten
        </Typography>
      </Container>
    );
  } else if (props.fullFeedPlant === "fullfeed") {
    return <FeedChart hourlyUnit={false} outputValues={props.outputValues} />;
  } else if (props.fullFeedPlant === "consumption") {
    return (
      <ConsumptionChart hourlyUnit={false} outputValues={props.outputValues} />
    );
  }
}

function CardDisplay(props) {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ fontSize: 20 }}>{props.title}</Typography>
        <Typography variant="h3" sx={{ textAlign: "center", mt: 4, mb: 2 }}>
          {props.value} %
        </Typography>
      </CardContent>
    </Card>
  );
}

export default function TabPerformance(props) {
  const [outputValues, setoutputValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [timeStamp, setTimeStamp] = useState();
  const [fullFeedPlant, setFullFeedPlant] = useState("unset");

  useEffect(() => {
    async function getPlantData() {
      await getPeriodData(props.plantRef, "heute")
        .then((response) => {
          // find valid data when found set timestap in state
          let latestData;

          if (response) {
            latestData = response.data.reverse().find((el) => {
              if (
                (el[1][0][0] !== null && el[1][0][0] !== 0) ||
                (el[1][0][1] !== null && el[1][0][1] !== 0)
              ) {
                setTimeStamp(el[0].split("T")[1].slice(0, 5) + " Uhr");
                return true;
              } else {
                return false;
              }
            });
          }

          // format the output values when there is valid data
          if (latestData !== undefined) {
            const formattedOutput = latestData[1][0]
              .map((val, i) => (i === 9 || i === 10 ? val : val * 4))
              .map((el) => {
                return el !== null
                  ? el.toLocaleString("de", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : el;
              });

            setoutputValues(formattedOutput);

            // check if the plant is a full feed plant
            setFullFeedPlant(
              formattedOutput[9] === null && formattedOutput[10] === null
                ? "fullfeed"
                : "consumption"
            );
          } else {
            setoutputValues([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          }
        })
        .catch(function (error) {
          console.log("error getting KPI data:", error);
        });
    }

    getPlantData();
  }, [props.plantRef]);

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 20 }}>Momentanwerte</Typography>
            <Typography sx={{ fontSize: 20 }}>{timeStamp}</Typography>
          </Box>
          <ChartDisplay
            outputValues={outputValues}
            fullFeedPlant={fullFeedPlant}
          />
        </CardContent>
      </Card>
      {fullFeedPlant === "consumption" && (
        <>
          <CardDisplay title={"Autarkie Quote"} value={outputValues[9]} />
          <CardDisplay
            title={"Eigenverbrauch Quote"}
            value={outputValues[10]}
          />
        </>
      )}
    </Stack>
  );
}
