import React from "react";

// mui components
import { Box, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// custom snippets
import TermsConditions from "../snippets/terms-conditions";

export default function Terms(props) {
  const theme = useTheme();
  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: "#fafafa",
        pb: 2,
        minHeight: "100vh",
        ...(props.sidebarOpen && {
          pl: { lg: `404px` },
        }),
        transition: theme.transitions.create("padding", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Box
        pt={15}
        pr={{ sm: 2, md: 6, lg: 10 }}
        pb={10}
        pl={{ sm: 2, md: 6, lg: 10 }}
        mb={2}
      >
        <Typography variant="h4" mb={2}>
          Impressum und Datenschutzerklärung
        </Typography>
        <TermsConditions />
      </Box>
    </Container>
  );
}
