import React from "react";

// mui components
import { Box, Typography } from "@mui/material";

export default function TermsConditions() {
  return (
    <Box pt={0} mb={2}>
      <Typography mb={3}>
        PV-Benchmark UG (haftungsbeschränkt) &#38; Co. KG, vertreten durch die
        PV-Benchmark Verwaltungs UG (haftungsbeschränkt)
        <br />
        <br />
        Schabing 1
        <br />
        83119 Obing
        <br />
        Germany
        <br />
        <br />
        Geschäftsführer: Christian Vodermayer
        <br />
        Amtsgericht Traunstein HRA: 11532
        <br />
        Finanzamt Traunstein Ust-IdNr.: DE295185695
        <br />
        Inhaltlich Verantwortlicher gem. § 18 Abs. 2 RStV: Christian Vodermayer
        (Anschrift s.o.)
        <br />
        <br />
        TEL: +49 (0) 8624 824 000 -1
        <br />
        Web: pv-benchmark [dot] com
        <br />
        Email: info [at] pv-benchmark [dot] com
      </Typography>
      <Typography variant="h6" mb={1}>
        1. Funktionsumfang der Webseite und Haftung
      </Typography>
      <Typography>
        Die Webseite stellt Produktionsdaten von Photovoltaikanlagen als auch
        die Information, ob Photovoltaik Wechselrichter nicht produzieren /
        kommunizieren auf Basis der durch das Photovoltaik-Monitoringportal 3E
        SynaptiQ (https://sqo.3esynaptiq.com) bereitgestellten Daten dar.
        Darüber hinausgehende Informationen über den Betriebszustand der
        Photovoltaikanlagen wie z. B. Minderperformance einzelner Wechselrichter
        oder der kompletten Photovoltaikanlage werden nicht bereitgestellt. Eine
        Haftung für die Vollständigkeit und Richtigkeit der angezeigten Daten
        wird nicht übernommen.
      </Typography>
      <Typography variant="h6" mb={1} mt={3}>
        2. Information über die Erhebung personenbezogener Daten
      </Typography>
      <Typography>
        Im Folgenden informieren wir über die Erhebung personenbezogener und
        sonstiger Daten bei Nutzung unserer Website. Personenbezogene Daten sind
        alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
        E-Mail-Adressen, Nutzerverhalten.
        <br />
        <br />
        Verantwortlicher für die Datenverarbeitung ist:
        <br />
        <br />
        PV-Benchmark UG (haftungsbeschränkt) &#38; Co. KG
        <br />
        Schabing 1
        <br />
        83119 Obing
        <br />
        Deutschland
        <br />
        Geschäftsführer: Christian Vodermayer
        <br />
        Amtsgericht Traunstein HRA: 11532
        <br />
        TEL: +49 (0) 8624 824 000 -1
        <br />
        Web: pv-benchmark [dot] com
        <br />
        Email: info [at] pv-benchmark [dot] com
      </Typography>
      <Typography variant="h6" mb={1} mt={3}>
        3. Erhebung von Daten beim Besuch unserer Website
      </Typography>
      <Typography>
        Beim Besuch unserer Website erheben wir nur die Daten, welche Ihr
        Browser an unseren Server übermittelt.
        <br />
        <br />
        Dies sind die folgenden Daten, die erforderlich sind, um Ihnen unsere
        Website anzuzeigen, die Stabilität und Sicherheit der Website zu
        gewährleisten und die mit der Website angebotenen Leistungen zur
        Verfügung zu stellen:
        <br />
      </Typography>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware</li>
        <li>API-Token und PV-Anlagendaten</li>
      </ul>
      <Typography>
        Die vorstehend genannten Daten werden nur sessionbasiert gespeichert und
        werden nach dem Logout oder bei Verlassen der Website automatisch
        gelöscht.
        <br />
        Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6
        Abs. 1 lit. f DSGVO.
        <br />
        Darüber hinaus werden beim Besuch der Website keine personenbezogenen
        Daten verarbeitet.
      </Typography>
      <Typography variant="h6" mb={1} mt={3}>
        4. Verwendung von Cookies und Trackingtools
      </Typography>
      <Typography>
        Wir setzen auf unserer Website weder Cookies noch Trackingtools ein.
      </Typography>
      <Typography variant="h6" mb={1} mt={3}>
        5. Ihre Rechte
      </Typography>
      <Typography>
        Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie
        betreffenden personenbezogenen Daten:
      </Typography>
      <ul>
        <li> Recht auf Auskunft, </li>
        <li> Recht auf Berichtigung oder Löschung, </li>
        <li> Recht auf Einschränkung der Verarbeitung, </li>
        <li> Recht auf Widerspruch gegen die Verarbeitung, </li>
        <li> Recht auf Datenübertragbarkeit, </li>
        <li>
          Recht auf Beschwerde bei der zuständigen Datenschutz-Aufsichtsbehörde.
        </li>
      </ul>
      <Typography>Sie haben damit das Recht:</Typography>
      <ul>
        <li>
          gemäß Art. 15 DS-GVO Auskunft über Ihre von uns verarbeiteten
          personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
          über die Verarbeitungszwecke, die Kategorie der personenbezogenen
          Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
          offengelegt wurden oder werden, die geplante Speicherdauer, das
          Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
          Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts,
          die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
          sowie über das Bestehen einer automatisierten Entscheidungsfindung
          einschließlich Profiling und ggf. aussagekräftigen Informationen zu
          deren Einzelheiten verlangen;
        </li>
        <li>
          gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder
          Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten
          zu verlangen;
        </li>
        <li>
          gemäß Art. 17 DS-GVO die Löschung Ihrer bei uns gespeicherten
          personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung
          zur Ausübung des Rechts auf freie Meinungsäußerung und Information,
          zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
          öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;
        </li>
        <li>
          gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten
          von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber
          deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie
          jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DS-GVO Widerspruch
          gegen die Verarbeitung eingelegt haben;
        </li>
        <li>
          gemäß Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie uns
          bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesebaren Format zu erhalten oder die Übermittlung an einen
          anderen Verantwortlichen zu verlangen;
        </li>
        <li>
          gemäß Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung jederzeit
          gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
          Datenverarbeitung, die auf dieser Einwilligung beruhte, für die
          Zukunft nicht mehr fortführen dürfen und
        </li>
        <li>
          gemäß Art. 77 DS-GVO sich bei einer Aufsichtsbehörde zu beschweren.
          Hierfür können Sie sich an das Bayerische Landesamt für
          Datenschutzaufsicht wenden.
        </li>
      </ul>
      <Typography variant="h6" mb={1} mt={3}>
        5. Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten
      </Typography>
      <Typography>
        Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben,
        können Sie diese jederzeit widerrufen. Ein solcher Widerruf beeinflusst
        die Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem
        Sie ihn gegenüber uns ausgesprochen haben.
        <br />
        <br />
        Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf ein
        berechtigtes Interesse stützen, können Sie Widerspruch gegen die
        Verarbeitung einlegen. Bei Ausübung eines solchen Widerspruchs bitten
        wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten
        nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres
        begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die
        Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden
        schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
        fortführen.
      </Typography>
    </Box>
  );
}
