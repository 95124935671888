import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectPeriod(props) {
  const handleChange = (event) => {
    props.setPeriod(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 240 }}>
      <FormControl variant="standard" fullWidth sx={{ pt: { xs: 1, md: 0 } }}>
        <InputLabel id="period-select-label">Zeitraum</InputLabel>
        <Select
          labelId="period-select-label"
          id="demo-simple-select"
          value={props.period}
          label="Zeitraum"
          onChange={handleChange}
        >
          <MenuItem value={"heute"}>Heute</MenuItem>
          <MenuItem value={"gestern"}>Gestern</MenuItem>
          <MenuItem value={"woche"}>diese Woche</MenuItem>
          <MenuItem value={"monat"}>dieser Monat</MenuItem>
          <MenuItem value={"jahr"}>dieses Jahr</MenuItem>
          <MenuItem value={"letztzezwoelf"}>Letzte 12 Monate</MenuItem>
          <MenuItem value={"letztesjahr"}>Letztes Jahr</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
