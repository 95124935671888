import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function EcolutionIcon(props) {
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 30">
        <path
          d="M1.9 8.6 13 2.2l11.1 6.4v12.8L13 27.8 1.9 21.4V8.6z"
          fill="none"
          stroke="#ffb400"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}
