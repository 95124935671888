import * as React from "react";
import { Link, useParams } from "react-router-dom";

// mui components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SolarPowerOutlined from "@mui/icons-material/SolarPowerOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import MuiLink from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

export default function SimpleAccordion(props) {
  const breakpoints = useTheme().breakpoints.values;
  let params = useParams();
  const currentPlant = params.anlagenId;

  function toggleSidebar() {
    if (window.innerWidth <= breakpoints.lg) {
      return props.toggleDrawer();
    }
  }

  return (
    <>
      <Accordion
        defaultExpanded={true}
        square={true}
        elevation={0}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AutoAwesomeMosaicOutlinedIcon color="action" sx={{ mr: 2 }} />
          <Typography>Meine Anlagen</Typography>
        </AccordionSummary>
        <AccordionDetails onClick={toggleSidebar()} sx={{ p: 0 }}>
          <List>
            {props.anlagendaten.map((anlage) => (
              <MuiLink
                component={Link}
                to={`/anlagen/${anlage.id}`}
                key={anlage.id}
                underline="none"
                color="inherit"
              >
                <ListItem key={anlage.id} disablePadding>
                  <ListItemButton
                    sx={{ pl: "24px !important" }}
                    selected={currentPlant === anlage.id ? true : false}
                  >
                    <ListItemIcon>
                      <SolarPowerOutlined />
                    </ListItemIcon>
                    <ListItemText primary={anlage.name} />
                  </ListItemButton>
                </ListItem>
              </MuiLink>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
