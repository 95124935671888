import * as React from "react";

// mui coponents
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  "&:not(:last-of-type)": {
    borderBottom: "1px solid " + theme.palette.grey[400],
  },
}));

export default function ControlledAccordions(props) {
  const [expanded, setExpanded] = React.useState(false);

  const notActive = props.inverters.notProducing.length;
  const notCommunicating = props.inverters.notCommunicating.length;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const InactiveInverters = () => {
    return (
      <Stack spacing={2}>
        {props.inverters.notProducing.map((inverter) => {
          return (
            !inverter.active && (
              <Item variant="string" square={true} key={inverter.name}>
                {inverter.name}
              </Item>
            )
          );
        })}
      </Stack>
    );
  };

  const ActiveInverters = () => {
    return (
      <Stack spacing={2}>
        {props.inverters.inverterlist.map((inverter) => {
          const allGood =
            inverter.communicating === true && inverter.active === true;
          return (
            allGood && (
              <Item variant="string" square={true} key={inverter.name}>
                {inverter.name}
              </Item>
            )
          );
        })}
      </Stack>
    );
  };

  const NotCommunicatingInverters = () => {
    return (
      <Stack spacing={2}>
        {props.inverters.notCommunicating.map((inverter) => {
          return (
            !inverter.communicating && (
              <Item variant="string" square={true} key={inverter.name}>
                {inverter.name}
              </Item>
            )
          );
        })}
      </Stack>
    );
  };

  return (
    <div>
      {props.inverters.active !== 0 && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Chip
              sx={{
                background: "rgba(46, 125, 50, 0.12)",
                color: "rgba(46, 125, 50, 1)",
                width: "fit-content",
                cursor: "pointer",
              }}
              label={
                props.inverters.active === props.inverters.count
                  ? "Alle Kommunizieren und Produzieren"
                  : props.inverters.active + " Kommunizieren und Produzieren"
              }
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              <ActiveInverters />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {notActive > 0 && (
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Chip
              sx={{
                background: "rgba(211, 47, 47, 0.12)",
                color: "rgba(211, 47, 47, 1)",
                width: "fit-content",
                cursor: "pointer",
              }}
              label={notActive + " Wechselrichter - keine Produktion"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              <InactiveInverters />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {notCommunicating > 0 && (
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>
              <Chip
                sx={{
                  background: "rgba(237, 108, 2, 0.12)",
                  color: "rgba(230, 81, 0, 1)",
                  width: "fit-content",
                  cursor: "pointer",
                }}
                label={
                  notCommunicating + " Wechselrichter - keine Kommunikation"
                }
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%" }}>
              <NotCommunicatingInverters />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
