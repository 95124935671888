import axios from "axios";

// get general plant data from api
function getPlantData() {
  // api token
  let token = "Bearer " + sessionStorage.getItem("token");

  // make request to get plant data
  const config = {
    method: "get",
    url: "/api/plants?envelope=true&sort=name&limit=1000",
    headers: {
      Authorization: token,
    },
  };

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const config = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !config._retry &&
        error.response.data.error.key === "token-expired"
      ) {
        config._retry = true;
        try {
          await refreshToken();
          config.headers["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          return axios(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("Error while loading plants:", error);
      return error;
    });
}

function getInverters(id) {
  // api token
  let token = "Bearer " + sessionStorage.getItem("token");

  // get invertes for plant an add it to data object
  const config = {
    method: "get",
    url: "/api/inverters?container=" + id + "&experimental=true&limit=100",
    headers: {
      Authorization: token,
    },
  };

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const config = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !config._retry &&
        error.response.data.error.key === "token-expired"
      ) {
        config._retry = true;
        try {
          await refreshToken();
          config.headers["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          return axios(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  return axios(config)
    .then(function (response) {
      // return plant array
      return response.data;
    })
    .catch(function (error) {
      console.log("Error getting plant inverters:", error);
      return error;
    });
}

//if the token is expired it will be refreshed
// overwrites the old token with the new one
function refreshToken() {
  return axios
    .post("/api/token/refresh", { token: sessionStorage.getItem("token") })
    .then(function (response) {
      // Save token to sessionStorage
      sessionStorage.setItem("token", response.data.token);
      console.log("token refreshed");
      return response;
    })
    .catch(function (error) {
      console.log("token refresh error:", error);
      //clear session storage and redirect user to base url
      sessionStorage.clear();
      window.location.assign("/");
      return error;
    });
}

function getPeriodData(id, period) {
  // api token
  let token = "Bearer " + sessionStorage.getItem("token");

  //generate actual date in the format yyyy-mm-ddThh:mm:ss
  let currentDateTime = new Date();
  let tag = currentDateTime.getDate();
  let monat = currentDateTime.getMonth() + 1;
  let jahr = currentDateTime.getFullYear();
  let startdate = "";
  let enddate = "";
  let bereich = "";
  let x = 0;

  switch (period) {
    case "heute":
      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      startdate = jahr + "-" + monat + "-" + tag + "T00:00:00";
      enddate = jahr + "-" + monat + "-" + tag + "T23:59:59";
      bereich = "15-minutes";

      break;
    case "gestern":
      tag -= 1;
      if (tag < 1) {
        tag = 1;
        monat -= 1;
      }
      if (monat < 1) {
        monat = 12;
        jahr -= 1;
      }

      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      startdate = jahr + "-" + monat + "-" + tag + "T00:00:00";
      enddate = jahr + "-" + monat + "-" + tag + "T23:59:59";
      bereich = "1-days";

      break;
    case "woche":
      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      enddate = jahr + "-" + monat + "-" + tag + "T23:59:59";

      let letzterMontag = new Date(
        currentDateTime.setDate(
          currentDateTime.getDate() - ((currentDateTime.getDay() + 6) % 7)
        )
      );

      tag = letzterMontag.getDate();
      monat = letzterMontag.getMonth() + 1;
      jahr = letzterMontag.getFullYear();

      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      startdate = jahr + "-" + monat + "-" + tag + "T00:00:00";
      bereich = "1-weeks";

      break;
    case "monat":
      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      enddate = jahr + "-" + monat + "-" + tag + "T23:59:59";
      startdate = jahr + "-" + monat + "-01T00:00:00";
      bereich = "1-months";

      break;
    case "jahr":
      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      enddate = jahr + "-" + monat + "-" + tag + "T23:59:59";
      startdate = jahr + "-01-01T00:00:00";
      bereich = "1-years";

      break;
    case "letztzezwoelf":
      if (monat === 1 ? (x = 2) : (x = 1));

      if (tag < 10 ? (tag = "0" + tag) : tag);
      if (monat < 10 ? (monat = "0" + monat) : monat);

      startdate = jahr - x + "-" + monat + "-01T00:00:00";
      enddate = jahr - x + 1 + "-" + monat + "-01T00:00:00";
      bereich = "1-months";

      break;
    case "letztesjahr":
      startdate = jahr - 1 + "-01-01T00:00:00";
      enddate = jahr + "-01-01T00:00:00";
      bereich = "1-years";

      break;
    default:
      //this is an error!!!
      console.log("there was no matching case");
  }

  const config = {
    method: "get",
    url:
      "/api/data?from_date=" +
      startdate +
      "&to_date=" +
      enddate +
      "&object=" +
      id +
      "&indicator=yield.meter.green" +
      "&indicator=yield.inverter" +
      "&indicator=energy.consumption" +
      "&indicator=energy.consumption.self" +
      "&indicator=energy.import" +
      "&indicator=energy.export" +
      "&indicator=income.inverter" +
      "&indicator=yield.specific.meter.green" +
      "&indicator=energy.specific" +
      "&indicator=index.selfSufficiency" +
      "&indicator=index.selfConsumption" +
      "&indicator=irradiation.actual" +
      "&granularity=" +
      bereich +
      "&limit=1000" +
      "&reply-tz=Europe/Berlin",
    headers: {
      Authorization: token,
    },
  };

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const config = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        error.config._retry !== true &&
        error.response.data.error.key === "token-expired"
      ) {
        config._retry = true;
        try {
          await refreshToken();
          config.headers["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          return axios(config);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  return axios(config).then(function (response) {
    return response.data;
  });
}

export { getPlantData, getInverters, refreshToken, getPeriodData };
