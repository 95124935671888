import React from "react";

export default function ConsumptionChart(props) {
  const valueReturn = (index) => {
    // define chart units
    let chartUnit = " kW";
    props.hourlyUnit && (chartUnit = " kWh");

    // define chart value
    let chartValue = props.outputValues[index];

    if (index === 0) {
      chartValue === "0" && (chartValue = props.outputValues[index + 1]);
    }

    // if chart value is higher than 10000 shorten it and use other unit
    const valueAsNumber = parseFloat(chartValue.replace(/\./g, ""));
    if (valueAsNumber > 10000) {
      chartValue = (valueAsNumber / 10000).toLocaleString("de", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      // redefine chart units
      chartUnit = " mW";
      props.hourlyUnit && (chartUnit = " MWh");
    }

    return chartValue + chartUnit;
  };

  return (
    <svg
      width="1088"
      height="956"
      viewBox="0 0 1088 956"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%", maxHeight: "55vh" }}
    >
      <g id="Property 1=Consumption-Chart">
        <rect width="1088" height="956" fill="white" />
        <g id="Group 38">
          <g id="Union">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M894.141 67C893.821 67 893.504 67.0535 893.202 67.1582L833.927 87.7164C832.605 88.1749 831.808 89.5208 832.04 90.9005C832.273 92.2803 833.467 93.2905 834.866 93.2905H891.275V121.225L834.935 141.108C833.619 141.573 832.829 142.919 833.064 144.295C833.3 145.671 834.493 146.677 835.889 146.677H890.686L859.112 257.772C858.757 259.023 859.288 260.357 860.407 261.02C861.525 261.684 862.951 261.511 863.878 260.599L944.754 181.102L966.412 248.782L926.138 210.569C924.99 209.479 923.176 209.527 922.086 210.675C920.997 211.824 921.044 213.638 922.192 214.727L970.576 260.635C971.525 261.535 972.964 261.679 974.073 260.983C975.181 260.287 975.677 258.928 975.278 257.682L939.757 146.677H994.465C995.861 146.677 997.054 145.671 997.29 144.295C997.526 142.919 996.736 141.573 995.419 141.108L939.079 121.225V93.2905H995.488C996.887 93.2905 998.081 92.2803 998.314 90.9005C998.546 89.5208 997.749 88.1749 996.427 87.7164L937.152 67.1582C936.85 67.0535 936.533 67 936.213 67H894.141ZM933.347 120.387V93.2905H897.007V120.387H933.347ZM897.007 126.119H933.347V140.945H897.007V126.119ZM891.275 127.304L852.623 140.945H891.275V127.304ZM896.646 146.677L888.608 174.959L915.524 200.498C915.758 200.72 915.946 200.972 916.088 201.242L942.798 174.987L933.738 146.677H896.646ZM939.079 140.945V127.304L977.732 140.945H939.079ZM897.007 87.5582H933.347V72.7323H897.007V87.5582ZM978.477 87.5582H939.079V73.8938L978.477 87.5582ZM891.275 87.5582V73.8938L851.877 87.5582H891.275ZM911.579 204.657C911.762 204.83 911.961 204.975 912.172 205.091L867.589 248.914L886.839 181.183L911.579 204.657Z"
              fill="#9E9E9E"
            />
            <path
              d="M834.866 101.423C833.283 101.423 832 102.706 832 104.289C832 105.872 833.283 107.155 834.866 107.155H850.405C851.987 107.155 853.271 105.872 853.271 104.289C853.271 102.706 851.987 101.423 850.405 101.423H834.866Z"
              fill="#9E9E9E"
            />
            <path
              d="M979.949 101.423C978.366 101.423 977.083 102.706 977.083 104.289C977.083 105.872 978.366 107.155 979.949 107.155H995.488C997.071 107.155 998.354 105.872 998.354 104.289C998.354 102.706 997.071 101.423 995.488 101.423H979.949Z"
              fill="#9E9E9E"
            />
            <path
              d="M835.889 154.81C834.306 154.81 833.023 156.093 833.023 157.676C833.023 159.259 834.306 160.542 835.889 160.542H851.427C853.01 160.542 854.294 159.259 854.294 157.676C854.294 156.093 853.01 154.81 851.427 154.81H835.889Z"
              fill="#9E9E9E"
            />
            <path
              d="M978.927 154.81C977.344 154.81 976.061 156.093 976.061 157.676C976.061 159.259 977.344 160.542 978.927 160.542H994.465C996.048 160.542 997.331 159.259 997.331 157.676C997.331 156.093 996.048 154.81 994.465 154.81H978.927Z"
              fill="#9E9E9E"
            />
          </g>
          <g id="Group 31">
            <g id="Union_2">
              <path
                d="M911.852 673.849C911.049 673.446 910.103 673.444 909.298 673.842L818.469 718.77C817.05 719.471 816.469 721.191 817.171 722.609C817.873 724.028 819.592 724.61 821.011 723.908L910.561 679.613L998.998 723.901C1000.41 724.61 1002.14 724.037 1002.84 722.622C1003.55 721.207 1002.98 719.485 1001.56 718.776L911.852 673.849Z"
                fill="#3F51B5"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M921.144 779.772C921.144 776.291 923.069 773.17 925.444 770.625C929.03 766.783 931.224 761.626 931.224 755.957C931.224 744.081 921.596 734.453 909.72 734.453C897.844 734.453 888.216 744.081 888.216 755.957C888.216 761.906 890.632 767.291 894.537 771.185C896.991 773.632 898.968 776.718 898.968 780.184C898.968 785.546 903.315 789.893 908.677 789.893H911.023C916.613 789.893 921.144 785.362 921.144 779.772ZM925.491 755.957C925.491 760.118 923.887 763.892 921.253 766.714C918.478 769.688 915.412 774.159 915.412 779.772C915.412 782.196 913.447 784.16 911.023 784.16H908.677C906.481 784.16 904.7 782.38 904.7 780.184C904.7 774.505 901.504 770.037 898.584 767.125C895.714 764.264 893.949 760.322 893.949 755.957C893.949 747.246 901.01 740.185 909.72 740.185C918.43 740.185 925.491 747.246 925.491 755.957Z"
                fill="#3F51B5"
              />
              <path
                d="M901.141 799.472C901.141 797.889 902.425 796.606 904.008 796.606H916.607C918.19 796.606 919.474 797.889 919.474 799.472C919.474 801.055 918.19 802.338 916.607 802.338H913.508V824.127C913.508 825.71 912.225 826.994 910.642 826.994H842.465C840.883 826.994 839.599 825.71 839.599 824.127V733.225C839.599 731.643 840.883 730.359 842.465 730.359C844.048 730.359 845.332 731.643 845.332 733.225V821.261H907.776V802.338H904.008C902.425 802.338 901.141 801.055 901.141 799.472Z"
                fill="#3F51B5"
              />
              <path
                d="M912.67 716.813C912.67 715.23 911.387 713.947 909.804 713.947C908.221 713.947 906.938 715.23 906.938 716.813V723.711C906.938 725.294 908.221 726.577 909.804 726.577C911.387 726.577 912.67 725.294 912.67 723.711V716.813Z"
                fill="#3F51B5"
              />
              <path
                d="M867.374 756.376C867.374 754.793 868.657 753.509 870.24 753.509H877.139C878.722 753.509 880.005 754.793 880.005 756.376C880.005 757.958 878.722 759.242 877.139 759.242H870.24C868.657 759.242 867.374 757.958 867.374 756.376Z"
                fill="#3F51B5"
              />
              <path
                d="M942.469 753.509C940.886 753.509 939.603 754.793 939.603 756.376C939.603 757.958 940.886 759.242 942.469 759.242H949.367C950.95 759.242 952.233 757.958 952.233 756.376C952.233 754.793 950.95 753.509 949.367 753.509H942.469Z"
                fill="#3F51B5"
              />
              <path
                d="M879.801 726.374C880.921 725.255 882.736 725.255 883.855 726.374L888.733 731.252C889.852 732.371 889.852 734.186 888.733 735.305C887.613 736.424 885.799 736.424 884.679 735.305L879.801 730.427C878.682 729.308 878.682 727.493 879.801 726.374Z"
                fill="#3F51B5"
              />
              <path
                d="M939.806 730.427C940.926 729.308 940.926 727.493 939.806 726.374C938.687 725.255 936.872 725.255 935.753 726.374L930.875 731.252C929.756 732.371 929.756 734.186 930.875 735.305C931.995 736.424 933.809 736.424 934.929 735.305L939.806 730.427Z"
                fill="#3F51B5"
              />
              <path
                d="M980.081 730.359C981.664 730.359 982.947 731.643 982.947 733.225V824.127C982.947 825.71 981.664 826.994 980.081 826.994H929.58C927.997 826.994 926.714 825.71 926.714 824.127C926.714 822.545 927.997 821.261 929.58 821.261H977.215V733.225C977.215 731.643 978.498 730.359 980.081 730.359Z"
                fill="#3F51B5"
              />
            </g>
            <text
              id="consumption"
              fill="#3F51B5"
              style={{ whiteSpace: "pre" }}
              fontFamily={"inherit"}
              fontSize="40"
              fontWeight="500"
              letterSpacing="0.15px"
              textAnchor="middle"
            >
              <tspan x="915" y="904.719">
                {valueReturn(2)}
              </tspan>
            </text>
          </g>
          <g id="Group 37">
            <path
              id="Union_3"
              d="M316.736 529.809C316.736 532.018 318.527 533.809 320.736 533.809H387.819C434.477 533.809 476.363 562.412 493.344 605.87L520.355 674.996C550.537 752.238 623.701 803.844 706.107 806.777C708.289 806.855 710.046 808.63 710.046 810.814V838.838C710.046 842.192 714.322 843.606 716.321 840.913L771.157 767.041C772.071 765.809 772.071 764.124 771.157 762.892L716.321 689.02C714.322 686.327 710.046 687.741 710.046 691.095V719.212C710.046 721.465 708.184 723.28 705.935 723.142C658.017 720.214 615.799 689.734 598.161 644.593L571.15 575.467C541.648 499.967 468.878 450.274 387.819 450.274H316.736V529.809Z"
              fill="#EBCB5A"
            />
            <text
              id="energyConsumptionSelf"
              fill="white"
              style={{ whiteSpace: "pre" }}
              fontFamily={"inherit"}
              fontSize="32"
              fontWeight="500"
              letterSpacing="0.15px"
            >
              <tspan x="334" y="502.803">
                {valueReturn(3)}
              </tspan>
            </text>
          </g>
          <g id="Group 38_2">
            <path
              id="Union_4"
              d="M768.906 178.147C769.821 179.379 769.821 181.064 768.906 182.296L714.071 256.168C712.072 258.861 707.795 257.447 707.795 254.094V226.879C707.795 224.604 705.898 222.781 703.629 222.947C654.866 226.506 611.466 256.185 590.651 300.992L576.199 332.103C542.144 405.412 468.652 452.307 387.819 452.307H316.736V372.772C316.736 370.563 318.527 368.772 320.736 368.772H387.819C436.144 368.772 480.08 340.737 500.44 296.91L514.892 265.798C549.443 191.421 622.494 142.83 703.896 139.262C706.062 139.167 707.795 137.397 707.795 135.229V106.35C707.795 102.996 712.072 101.582 714.071 104.275L768.906 178.147Z"
              fill="#E3B615"
            />
            <text
              id="energyExport"
              fill="white"
              style={{ whiteSpace: "pre" }}
              fontFamily={"inherit"}
              fontSize="32"
              fontWeight="500"
              letterSpacing="0.15px"
            >
              <tspan x="334.139" y="421.184">
                {valueReturn(5)}
              </tspan>
            </text>
          </g>
          <g id="Group 32">
            <path
              id="Union_5"
              d="M956.081 562.283C956.081 566.128 959.198 569.244 963.042 569.244H988.334C991.673 569.244 993.096 573.489 990.432 575.502L916.412 631.429C915.171 632.367 913.457 632.367 912.216 631.429L838.196 575.502C835.532 573.489 836.955 569.244 840.294 569.244H865.585C869.43 569.244 872.546 566.128 872.546 562.283V306.14C872.546 302.295 875.663 299.179 879.508 299.179H949.12C952.964 299.179 956.081 302.295 956.081 306.14L956.081 562.283Z"
              fill="#D56A48"
            />
            <g id="Group 36">
              <rect
                id="Rectangle 6"
                x="804"
                y="400.669"
                width="222.24"
                height="86.496"
                rx="2"
                fill="white"
                stroke="#D56A48"
                strokeWidth="4"
              />
              <text
                id="energyImport"
                fill="#D56A48"
                style={{ whiteSpace: "pre" }}
                fontFamily={"inherit"}
                fontSize="40"
                fontWeight="500"
                letterSpacing="0.15px"
                x="915"
                textAnchor="middle"
                y="457.286"
              >
                <tspan>{valueReturn(4)}</tspan>
              </text>
            </g>
          </g>
          <g id="Group 35">
            <text
              id="yieldMeter"
              fill="#CFA81A"
              style={{ whiteSpace: "pre" }}
              fontFamily={"inherit"}
              fontSize="40"
              fontWeight="500"
              letterSpacing="0.15px"
              textAnchor="middle"
            >
              <tspan x="165" y="569.535">
                {valueReturn(0)}
              </tspan>
            </text>
            <g id="Union_6">
              <path
                d="M95.3236 264.366C96.9066 264.366 98.1898 265.649 98.1898 267.232V274.55C98.1898 276.133 96.9066 277.416 95.3236 277.416C93.7407 277.416 92.4575 276.133 92.4575 274.55V267.232C92.4575 265.649 93.7407 264.366 95.3236 264.366Z"
                fill="#CFA81A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.8754 332.862C108.19 332.862 118.984 322.068 118.984 308.753C118.984 295.438 108.19 284.644 94.8754 284.644C81.5605 284.644 70.7667 295.438 70.7667 308.753C70.7667 322.068 81.5605 332.862 94.8754 332.862ZM94.8754 327.129C105.024 327.129 113.252 318.902 113.252 308.753C113.252 298.604 105.024 290.376 94.8754 290.376C84.7264 290.376 76.499 298.604 76.499 308.753C76.499 318.902 84.7264 327.129 94.8754 327.129Z"
                fill="#CFA81A"
              />
              <path
                d="M53.3553 306.338C51.7724 306.338 50.4892 307.621 50.4892 309.204C50.4892 310.787 51.7724 312.07 53.3553 312.07H60.6734C62.2564 312.07 63.5396 310.787 63.5396 309.204C63.5396 307.621 62.2564 306.338 60.6734 306.338H53.3553Z"
                fill="#CFA81A"
              />
              <path
                d="M127.114 309.204C127.114 307.621 128.397 306.338 129.98 306.338H137.298C138.881 306.338 140.164 307.621 140.164 309.204C140.164 310.787 138.881 312.07 137.298 312.07H129.98C128.397 312.07 127.114 310.787 127.114 309.204Z"
                fill="#CFA81A"
              />
              <path
                d="M67.675 277.499C66.5557 276.379 64.741 276.379 63.6217 277.499C62.5024 278.618 62.5024 280.433 63.6217 281.552L68.7964 286.727C69.9157 287.846 71.7304 287.846 72.8497 286.727C73.969 285.607 73.969 283.793 72.8497 282.673L67.675 277.499Z"
                fill="#CFA81A"
              />
              <path
                d="M127.032 277.499C128.151 278.618 128.151 280.433 127.032 281.552L121.857 286.727C120.738 287.846 118.923 287.846 117.804 286.727C116.684 285.607 116.684 283.793 117.804 282.673L122.978 277.499C124.098 276.379 125.912 276.379 127.032 277.499Z"
                fill="#CFA81A"
              />
              <path
                d="M92.4575 350.724C92.4575 352.307 93.7407 353.59 95.3236 353.59C96.9066 353.59 98.1898 352.307 98.1898 350.724V343.405C98.1898 341.823 96.9066 340.539 95.3236 340.539C93.7407 340.539 92.4575 341.823 92.4575 343.405V350.724Z"
                fill="#CFA81A"
              />
              <path
                d="M127.032 340.458C125.912 341.577 124.098 341.577 122.978 340.458L117.804 335.283C116.684 334.164 116.684 332.349 117.804 331.23C118.923 330.111 120.738 330.111 121.857 331.23L127.032 336.405C128.151 337.524 128.151 339.339 127.032 340.458Z"
                fill="#CFA81A"
              />
              <path
                d="M63.6211 336.405C62.5018 337.524 62.5018 339.339 63.6211 340.458C64.7404 341.577 66.5551 341.577 67.6744 340.458L72.8491 335.283C73.9684 334.164 73.9684 332.349 72.8491 331.23C71.7298 330.111 69.915 330.111 68.7957 331.23L63.6211 336.405Z"
                fill="#CFA81A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M153.399 353.451C153.9 352.529 154.866 351.954 155.916 351.954H278.642C279.653 351.954 280.589 352.487 281.106 353.356C281.622 354.225 281.643 355.302 281.16 356.19L225.534 458.45C225.032 459.373 224.066 459.947 223.016 459.947H100.291C99.2799 459.947 98.3438 459.414 97.8272 458.545C97.3106 457.676 97.2901 456.599 97.7732 455.711L116.505 421.276V420.491H116.932L153.399 353.451ZM105.113 454.215L120.339 426.223H154.722L139.496 454.215H105.113ZM146.021 454.215L161.248 426.223H195.631L180.404 454.215H146.021ZM164.366 420.491H198.749L214.567 391.41H180.184L164.366 420.491ZM183.302 385.678H217.685L232.912 357.687H198.529L183.302 385.678ZM221.093 391.41L205.274 420.491H238.731V422.193L255.476 391.41H221.093ZM258.594 385.678H224.211L239.437 357.687H273.82L258.594 385.678ZM176.777 385.678L192.003 357.687H157.62L142.394 385.678H176.777ZM139.276 391.41H173.659L157.84 420.491H123.457L139.276 391.41ZM186.93 454.215L202.156 426.223H236.539L221.313 454.215H186.93Z"
                fill="#CFA81A"
              />
              <path
                d="M247.833 452.531C247.833 450.948 246.55 449.665 244.967 449.665C243.384 449.665 242.101 450.948 242.101 452.531V489.519C242.101 489.824 242.149 490.119 242.237 490.395H204.045C204.133 490.119 204.181 489.824 204.181 489.519V480.816C204.181 479.233 202.898 477.95 201.315 477.95C199.732 477.95 198.449 479.233 198.449 480.816V489.519C198.449 489.824 198.497 490.119 198.585 490.395H191.337C189.754 490.395 188.471 491.678 188.471 493.261C188.471 494.844 189.754 496.127 191.337 496.127H254.945C256.528 496.127 257.811 494.844 257.811 493.261C257.811 491.678 256.528 490.395 254.945 490.395H247.697C247.785 490.119 247.833 489.824 247.833 489.519V452.531Z"
                fill="#CFA81A"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
