import React from "react";
import { Link, useParams } from "react-router-dom";

// mui components
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// custom components
import Tabs from "../components/tabs";

function handleClick(event) {
  event.preventDefault();
}

// find current plant in plants data
function getPlant(id) {
  // return plant data when available else redirict to base url
  if (
    sessionStorage.getItem("plants") &&
    JSON.parse(sessionStorage.getItem("plants")).find(
      (plant) => plant.id === id
    )
  ) {
    return JSON.parse(sessionStorage.getItem("plants")).find(
      (plant) => plant.id === id
    );
  }
}

export default function Plant(props) {
  const theme = useTheme();

  let params = useParams();
  let plant = getPlant(params.anlagenId);

  // when plant is not found return to homepage with plants
  if (plant === undefined) {
    return window.location.assign("/");
  }

  const plantRef = plant.ref;

  return (
    <Container
      maxWidth="false"
      sx={{
        bgcolor: "#fafafa",
        pb: 2,
        minHeight: "100vh",
        px: { lg: "64px !important" },
        ...(props.sidebarOpen && {
          pl: { lg: `444px` },
        }),
        transition: theme.transitions.create("padding", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
    >
      <Box pt={10}>
        <Breadcrumbs
          sx={{ fontSize: 16 }}
          aria-label="breadcrumb"
          onClick={handleClick}
        >
          <MuiLink
            component={Link}
            underline="hover"
            color="inherit"
            to={"/anlagen"}
          >
            Meine Anlagen
          </MuiLink>
          <Typography variant="link">{plant.id}</Typography>
        </Breadcrumbs>
      </Box>
      <Stack sx={{ mt: 1 }} spacing={1}>
        <Typography sx={{ fontSize: 24 }} color="text.primary">
          {plant.name}
        </Typography>
        <Typography sx={{ fontSize: 16 }} color="text.secondary">
          Leistung: {plant.capacity_dc.toFixed(2).replace(".", ",")} kWp
        </Typography>
        <Typography sx={{ fontSize: 16 }} color="text.secondary">
          Wechselrichter: {plant.inverters.count}
        </Typography>
      </Stack>
      <Tabs plantRef={plantRef} plant={plant} />
    </Container>
  );
}
