import React from "react";

export default function ConsumptionChart(props) {
  const valueReturn = (index) => {
    // define chart units
    let chartUnit = " kW";
    props.hourlyUnit && (chartUnit = " kWh");

    // define chart value
    let chartValue = props.outputValues[index];

    if (index === 0) {
      chartValue === "0" && (chartValue = props.outputValues[index + 1]);
    }

    // if chart value is higher than 10000 shorten it and use other unit
    const valueAsNumber = parseFloat(chartValue.replace(/\./g, ""));
    if (valueAsNumber > 10000) {
      chartValue = (valueAsNumber / 10000).toLocaleString("de", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      // redefine chart units
      chartUnit = " mW";
      props.hourlyUnit && (chartUnit = " MWh");
    }

    return chartValue + chartUnit;
  };

  return (
    <svg
      width="1088"
      height="359"
      viewBox="0 0 1088 359"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "100%" }}
    >
      <g id="Property 1=Variant4">
        <rect width="1088" height="359" fill="white" />
        <g id="Group 38">
          <g id="Union">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M895.141 85.6343C894.821 85.6343 894.504 85.6878 894.202 85.7925L834.927 106.351C833.605 106.809 832.808 108.155 833.04 109.535C833.273 110.915 834.467 111.925 835.866 111.925H892.275V139.859L835.935 159.743C834.619 160.207 833.829 161.553 834.064 162.929C834.3 164.305 835.493 165.312 836.889 165.312H891.686L860.112 276.406C859.757 277.657 860.288 278.991 861.407 279.655C862.525 280.318 863.951 280.145 864.878 279.234L945.754 199.736L967.412 267.416L927.138 229.203C925.99 228.114 924.176 228.161 923.086 229.31C921.997 230.458 922.044 232.272 923.192 233.362L971.576 279.269C972.525 280.17 973.964 280.313 975.073 279.617C976.181 278.921 976.677 277.563 976.278 276.316L940.757 165.312H995.465C996.861 165.312 998.054 164.305 998.29 162.929C998.526 161.553 997.736 160.207 996.419 159.743L940.079 139.859V111.925H996.488C997.887 111.925 999.081 110.915 999.314 109.535C999.546 108.155 998.749 106.809 997.427 106.351L938.152 85.7925C937.85 85.6878 937.533 85.6343 937.213 85.6343H895.141ZM934.347 139.021V111.925H898.007V139.021H934.347ZM898.007 144.753H934.347V159.579H898.007V144.753ZM892.275 145.938L853.623 159.579H892.275V145.938ZM897.646 165.312L889.608 193.594L916.524 219.133C916.758 219.354 916.946 219.606 917.088 219.876L943.798 193.622L934.738 165.312H897.646ZM940.079 159.579V145.938L978.732 159.579H940.079ZM898.007 106.192H934.347V91.3665H898.007V106.192ZM979.477 106.192H940.079V92.5281L979.477 106.192ZM892.275 106.192V92.5281L852.877 106.192H892.275ZM912.579 223.291C912.762 223.464 912.961 223.609 913.172 223.725L868.589 267.548L887.839 199.817L912.579 223.291Z"
              fill="#9E9E9E"
            />
            <path
              d="M835.866 120.057C834.283 120.057 833 121.341 833 122.923C833 124.506 834.283 125.79 835.866 125.79H851.405C852.987 125.79 854.271 124.506 854.271 122.923C854.271 121.341 852.987 120.057 851.405 120.057H835.866Z"
              fill="#9E9E9E"
            />
            <path
              d="M980.949 120.057C979.366 120.057 978.083 121.341 978.083 122.923C978.083 124.506 979.366 125.79 980.949 125.79H996.488C998.071 125.79 999.354 124.506 999.354 122.923C999.354 121.341 998.071 120.057 996.488 120.057H980.949Z"
              fill="#9E9E9E"
            />
            <path
              d="M836.889 173.444C835.306 173.444 834.023 174.727 834.023 176.31C834.023 177.893 835.306 179.176 836.889 179.176H852.427C854.01 179.176 855.294 177.893 855.294 176.31C855.294 174.727 854.01 173.444 852.427 173.444H836.889Z"
              fill="#9E9E9E"
            />
            <path
              d="M979.927 173.444C978.344 173.444 977.061 174.727 977.061 176.31C977.061 177.893 978.344 179.176 979.927 179.176H995.465C997.048 179.176 998.331 177.893 998.331 176.31C998.331 174.727 997.048 173.444 995.465 173.444H979.927Z"
              fill="#9E9E9E"
            />
          </g>
          <g id="Group 38_2">
            <g id="Group 38_3">
              <path
                id="Union_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M802.408 195.911C803.178 197.185 803.178 198.78 802.408 200.054L744.158 296.3C742.062 299.764 736.736 298.277 736.736 294.229V269.727C736.736 267.518 734.945 265.727 732.736 265.727H622.039H540.248H394.932H323C320.791 265.727 319 263.936 319 261.727V134.727C319 132.518 320.791 130.727 323 130.727H394.932H540.248H622.039H732.736C734.945 130.727 736.736 128.936 736.736 126.727V101.736C736.736 97.6876 742.062 96.2015 744.158 99.6654L802.408 195.911Z"
                fill="#E3B615"
              />
              <text
                id="30 W"
                fill="white"
                fontFamily={"inherit"}
                fontSize="64"
                fontWeight="500"
                letterSpacing="0.15px"
              >
                <tspan x="345.662" y="219.891">
                  {valueReturn(0)}
                </tspan>
              </text>
            </g>
          </g>
          <g id="Group 35">
            <g id="Union_3">
              <path
                d="M95.3236 37C96.9066 37 98.1898 38.2832 98.1898 39.8661V47.1842C98.1898 48.7672 96.9066 50.0504 95.3236 50.0504C93.7407 50.0504 92.4575 48.7672 92.4575 47.1842V39.8661C92.4575 38.2832 93.7407 37 95.3236 37Z"
                fill="#CFA81A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M94.8754 105.496C108.19 105.496 118.984 94.702 118.984 81.3871C118.984 68.0723 108.19 57.2784 94.8754 57.2784C81.5605 57.2784 70.7667 68.0723 70.7667 81.3871C70.7667 94.702 81.5605 105.496 94.8754 105.496ZM94.8754 99.7635C105.024 99.7635 113.252 91.5361 113.252 81.3871C113.252 71.2381 105.024 63.0107 94.8754 63.0107C84.7264 63.0107 76.499 71.2381 76.499 81.3871C76.499 91.5361 84.7264 99.7635 94.8754 99.7635Z"
                fill="#CFA81A"
              />
              <path
                d="M53.3553 78.9718C51.7724 78.9718 50.4892 80.255 50.4892 81.8379C50.4892 83.4208 51.7724 84.7041 53.3553 84.7041H60.6734C62.2564 84.7041 63.5396 83.4208 63.5396 81.8379C63.5396 80.255 62.2564 78.9718 60.6734 78.9718H53.3553Z"
                fill="#CFA81A"
              />
              <path
                d="M127.114 81.8379C127.114 80.255 128.397 78.9718 129.98 78.9718H137.298C138.881 78.9718 140.164 80.255 140.164 81.8379C140.164 83.4208 138.881 84.7041 137.298 84.7041H129.98C128.397 84.7041 127.114 83.4208 127.114 81.8379Z"
                fill="#CFA81A"
              />
              <path
                d="M67.675 50.1328C66.5557 49.0135 64.741 49.0135 63.6217 50.1328C62.5024 51.2521 62.5024 53.0668 63.6217 54.1861L68.7964 59.3608C69.9157 60.4801 71.7304 60.4801 72.8497 59.3608C73.969 58.2415 73.969 56.4268 72.8497 55.3075L67.675 50.1328Z"
                fill="#CFA81A"
              />
              <path
                d="M127.032 50.1328C128.151 51.2521 128.151 53.0668 127.032 54.1861L121.857 59.3608C120.738 60.4801 118.923 60.4801 117.804 59.3608C116.684 58.2415 116.684 56.4268 117.804 55.3075L122.978 50.1328C124.098 49.0135 125.912 49.0135 127.032 50.1328Z"
                fill="#CFA81A"
              />
              <path
                d="M92.4575 123.358C92.4575 124.941 93.7407 126.224 95.3236 126.224C96.9066 126.224 98.1898 124.941 98.1898 123.358V116.04C98.1898 114.457 96.9066 113.174 95.3236 113.174C93.7407 113.174 92.4575 114.457 92.4575 116.04V123.358Z"
                fill="#CFA81A"
              />
              <path
                d="M127.032 113.092C125.912 114.212 124.098 114.212 122.978 113.092L117.804 107.918C116.684 106.798 116.684 104.984 117.804 103.864C118.923 102.745 120.738 102.745 121.857 103.864L127.032 109.039C128.151 110.158 128.151 111.973 127.032 113.092Z"
                fill="#CFA81A"
              />
              <path
                d="M63.6211 109.039C62.5018 110.158 62.5018 111.973 63.6211 113.092C64.7404 114.212 66.5551 114.212 67.6744 113.092L72.8491 107.918C73.9684 106.798 73.9684 104.984 72.8491 103.864C71.7298 102.745 69.915 102.745 68.7957 103.864L63.6211 109.039Z"
                fill="#CFA81A"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M153.399 126.085C153.9 125.163 154.866 124.589 155.916 124.589H278.642C279.653 124.589 280.589 125.121 281.106 125.99C281.622 126.859 281.643 127.936 281.16 128.824L225.534 231.085C225.032 232.007 224.066 232.581 223.016 232.581H100.291C99.2799 232.581 98.3438 232.049 97.8272 231.18C97.3106 230.31 97.2901 229.234 97.7732 228.345L116.505 193.91V193.125H116.932L153.399 126.085ZM105.113 226.849L120.339 198.857H154.722L139.496 226.849H105.113ZM146.021 226.849L161.248 198.857H195.631L180.404 226.849H146.021ZM164.366 193.125H198.749L214.567 164.045H180.184L164.366 193.125ZM183.302 158.312H217.685L232.912 130.321H198.529L183.302 158.312ZM221.093 164.045L205.274 193.125H238.731V194.827L255.476 164.045H221.093ZM258.594 158.312H224.211L239.437 130.321H273.82L258.594 158.312ZM176.777 158.312L192.003 130.321H157.62L142.394 158.312H176.777ZM139.276 164.045H173.659L157.84 193.125H123.457L139.276 164.045ZM186.93 226.849L202.156 198.857H236.539L221.313 226.849H186.93Z"
                fill="#CFA81A"
              />
              <path
                d="M247.833 225.165C247.833 223.582 246.55 222.299 244.967 222.299C243.384 222.299 242.101 223.582 242.101 225.165V262.153C242.101 262.458 242.149 262.753 242.237 263.029H204.045C204.133 262.753 204.181 262.459 204.181 262.153V253.45C204.181 251.867 202.898 250.584 201.315 250.584C199.732 250.584 198.449 251.867 198.449 253.45V262.153C198.449 262.459 198.497 262.753 198.585 263.029H191.337C189.754 263.029 188.471 264.312 188.471 265.895C188.471 267.478 189.754 268.761 191.337 268.761H254.945C256.528 268.761 257.811 267.478 257.811 265.895C257.811 264.312 256.528 263.029 254.945 263.029H247.697C247.785 262.753 247.833 262.458 247.833 262.153V225.165Z"
                fill="#CFA81A"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
