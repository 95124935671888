import React, { useState } from "react";
import PropTypes from "prop-types";

// mui components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

// custom snippets
import SelectPeriod from "../snippets/select-period";
import TabPerformance from "../snippets/tab-performance";
import TabEnergy from "../snippets/tab-energy";
import TabAlarms from "../snippets/tab-alarms";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3, mb: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 13,
  },
}));

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [period, setPeriod] = useState("heute");

  const plantErrors =
    props.plant.inverters.notCommunicating.length +
    props.plant.inverters.notProducing.length;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", mt: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          variant={window.innerWidth > 600 ? "standard" : "fullWidth"}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Leistung" {...a11yProps(0)} />
          <Tab label="Energie" {...a11yProps(1)} />
          <Tab
            label={
              <StyledBadge
                badgeContent={plantErrors}
                color={
                  props.plant.inverters.notProducing.length === 0
                    ? "warning"
                    : "error"
                }
              >
                <NotificationsIcon />
              </StyledBadge>
            }
            {...a11yProps(2)}
          />
        </Tabs>
        {value === 1 && (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <SelectPeriod period={period} setPeriod={setPeriod} />
          </Box>
        )}
      </Box>
      <TabPanel value={value} index={0}>
        <TabPerformance plantRef={props.plantRef} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabEnergy
          plantRef={props.plantRef}
          inverters={props.plant.inverters}
          period={period}
          setPeriod={setPeriod}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabAlarms inverters={props.plant.inverters} />
      </TabPanel>
    </Box>
  );
}
