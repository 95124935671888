import React, { useState, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

import { getPeriodData } from "../utils/apiCalls";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

export default function PlantCard(props) {
  const [specificYield, setSpecificYield] = useState("unset");

  useEffect(() => {
    async function getSpecificYield() {
      await getPeriodData(props.object_ref, "gestern")
        .then((response) => {
          let responseValue = response.data[0][1][0][7];

          // if yield.specific.meter.green is not available use energy.specific
          if (responseValue === null) {
            responseValue = response.data[0][1][0][8];
          }
          // format response data
          if (responseValue === null) {
            responseValue = "Keine spezifische Energie gestern";
          } else {
            responseValue =
              "Spezifische Energie gestern: " +
              responseValue.toFixed(2).replace(".", ",") +
              " kWh/kWp";
          }

          // update data in state
          setSpecificYield(responseValue);
        })
        .catch(function (error) {
          console.log(
            "error getting specific energy data for plant card:",
            error
          );
        });
    }

    getSpecificYield();
  }, [props.object_ref]);

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          transition: theme.transitions.create(["backgroundColor"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Typography component="div">{props.name}</Typography>
            <Typography
              color="text.secondary"
              fontSize="16px"
              variant="body2"
              component="div"
            >
              {props.capacity_dc.toFixed(2).replace(".", ",")} kWp
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingTop: "32px" }}
              color="text.secondary"
              component="div"
            >
              Wechselrichter: {props.inverters.count}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row-reverse" },
              }}
            >
              <Box
                sx={{ display: { md: "flex" }, alignItems: { md: "flex-end" } }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="div"
                  sx={{
                    marginBottom: { xs: "16px", md: "0px" },
                    verticalAlign: { md: "bottom" },
                  }}
                >
                  {specificYield === "unset" ? (
                    <Skeleton width={240} />
                  ) : (
                    specificYield
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                {props.inverters.notCommunicating.length > 0 && (
                  <>
                    {props.inverters.active !== 0 && (
                      <Chip
                        sx={{
                          background: "rgba(46, 125, 50, 0.12)",
                          color: "rgba(46, 125, 50, 1)",
                          width: "fit-content",
                        }}
                        label={
                          props.inverters.active + " Wechselrichter sind aktiv"
                        }
                      />
                    )}
                    <Chip
                      sx={{
                        background: "rgba(237, 108, 2, 0.12)",
                        color: "rgba(230, 81, 0, 1)",
                        width: "fit-content",
                      }}
                      label={
                        props.inverters.count ===
                        props.inverters.notCommunicating.length
                          ? "Anlage kommuniziert nicht"
                          : props.inverters.notCommunicating.length +
                            " Wechselrichter - keine Kommunikation"
                      }
                    />
                  </>
                )}
                {props.inverters.notProducing.length > 0 && (
                  <Chip
                    sx={{
                      background: "rgba(211, 47, 47, 0.12)",
                      color: "rgba(211, 47, 47, 1)",
                      width: "fit-content",
                    }}
                    label={
                      props.inverters.count ===
                      props.inverters.notProducing.length
                        ? "Anlage produziert nicht"
                        : props.inverters.notProducing.length +
                          " Wechselrichter - keine Produktion"
                    }
                  />
                )}
                {props.inverters.notCommunicating.length === 0 &&
                  props.inverters.notProducing.length === 0 && (
                    <Chip
                      sx={{
                        background: "rgba(46, 125, 50, 0.12)",
                        color: "rgba(46, 125, 50, 1)",
                        width: "fit-content",
                      }}
                      label={"Alle kommunizieren  und produzieren"}
                    />
                  )}
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
