import React, { useState, useEffect } from "react";

// mui components
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

// custom snippets
import ConsumptionChart from "./consumptionChart";
import FeedChart from "./feedChart";
import SelectPeriod from "./select-period";
import { getPeriodData } from "../utils/apiCalls";

function approvePeriod(period) {
  const date = new Date();

  const dayOfWeek = date.getDay();
  const day = date.getDate();
  const month = date.getMonth() + 1;

  // when it is the frist day of the week return "heute"
  if (period === "woche" && dayOfWeek === 1) return "heute";
  // when it is the frist day of the month return "heute"
  if (period === "monat" && day === 1) return "heute";
  // when it is the frist day of the year return "heute"
  if (period === "jahr" && day === 1 && month === 1) return "heute";
  // if there is no match return period as is
  return period;
}

function ChartDisplay(props) {
  const { fullFeedPlant, outputValues } = props;
  if (outputValues[0] === 0 || outputValues[1] === 0) {
    return (
      <Container sx={{ textAlign: "center", py: 20 }}>
        <Typography color="text.disabled" sx={{ fontSize: 20 }}>
          keine Daten
        </Typography>
      </Container>
    );
  } else if (fullFeedPlant === "fullfeed") {
    return <FeedChart hourlyUnit={true} outputValues={outputValues} />;
  } else if (fullFeedPlant === "consumption") {
    return <ConsumptionChart hourlyUnit={true} outputValues={outputValues} />;
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

export default function TabEnergy(props) {
  const [outputValues, setoutputValues] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [fullFeedPlant, setFullFeedPlant] = useState("unset");

  // return formatted values
  const valueReturn = (index) => {
    if (index === 0) {
      return outputValues[index] !== "0"
        ? outputValues[index] + " kWh"
        : outputValues[index + 1] + " kWh";
    } else if (index === 7) {
      return outputValues[index] !== "0"
        ? outputValues[index] + " kWh/kWp"
        : outputValues[index + 1] + " kWh/kWp";
    } else {
      return outputValues[index] + " kWh";
    }
  };

  useEffect(() => {
    async function getPlantData() {
      const confirmedPeriod = approvePeriod(props.period);

      await getPeriodData(props.plantRef, confirmedPeriod)
        .then((response) => {
          // in case multiple data arrays are returned from api sum them to one value
          const reducedValue = (arr, i) => {
            return arr
              .map((el) => el[1][0]) // return the array in the array with the values
              .map((el) => el[i]) // return the value from the given index
              .filter((num) => num !== null) // filter out all null values
              .reduce((a, b) => a + b, 0); // sum the single values on the given index position
          };

          const reducedValues = [];

          // loop over the first data array to sum all values into new reduced array
          response.data[0][1][0].forEach((el, i) =>
            reducedValues.push(reducedValue(response.data, i))
          );

          // check if the plant is a full feed plant
          response.data.find(
            (arr) =>
              typeof arr[1][0][10] === "number" ||
              typeof arr[1][0][9] === "number"
          )
            ? setFullFeedPlant("consumption")
            : setFullFeedPlant("fullfeed");

          // format the output values
          const formattedOutput = reducedValues.map((el, i) => {
            if (i !== 11) {
              return el !== null
                ? el.toLocaleString("de", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : el;
            } else {
              return el !== null
                ? (el / 1000).toLocaleString("de", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                : el;
            }
          });

          setoutputValues(formattedOutput);
        })
        .catch(function (error) {
          console.log("error getting KPI data:", error);
        });
    }

    getPlantData();
  }, [props.plantRef, props.period]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid sx={{ display: { md: "none" }, pb: 2 }} item xs={12}>
          <SelectPeriod
            period={props.period}
            setPeriod={props.setPeriod}
            sx={{ flexGrow: 1 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Item>
            <ChartDisplay
              fullFeedPlant={fullFeedPlant}
              outputValues={outputValues}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Item>
            <Typography
              noWrap
              sx={{
                fontSize: 20,
              }}
            >
              Energie Produktion{outputValues[0] !== "0" && " - (Meter)"}
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mt: 4, mb: 2, whiteSpace: "nowrap" }}
            >
              {valueReturn(0)}
            </Typography>
          </Item>
        </Grid>
        {fullFeedPlant === "consumption" && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Energie Gesamtverbrauch
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    mb: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  {outputValues[2] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[2] + " kWh"
                  )}
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Energie Eigenverbrauch
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", mt: 4, mb: 2 }}
                >
                  {outputValues[3] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[3] + " kWh"
                  )}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Energie Netzbezug
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    mb: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  {outputValues[4] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[4] + " kWh"
                  )}
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Energie Netzeinspeisung
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    mb: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  {outputValues[5] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[5] + " kWh"
                  )}
                </Typography>
              </Item>
            </Grid>
          </>
        )}
        {fullFeedPlant === "fullfeed" && (
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <Typography noWrap sx={{ fontSize: 20 }}>
                Einspeisevergütung in Euro
              </Typography>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", mt: 4, mb: 2, whiteSpace: "nowrap" }}
              >
                {outputValues[6] === 0 ? (
                  <CircularProgress />
                ) : (
                  outputValues[6] + " €"
                )}
              </Typography>
            </Item>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Item>
            <Typography noWrap sx={{ fontSize: 20 }}>
              Spezifische Energie{outputValues[7] !== "0" && " - (Meter)"}
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mt: 4, mb: 2, whiteSpace: "nowrap" }}
            >
              {valueReturn(7)}
            </Typography>
          </Item>
        </Grid>
        {fullFeedPlant === "consumption" && props.dataArrayLength === 1 && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Autarkiequote
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    mb: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  {outputValues[9] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[9] + " %"
                  )}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Item>
                <Typography noWrap sx={{ fontSize: 20 }}>
                  Eigenverbrauchsquote
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mt: 4,
                    mb: 2,
                    whiteSpace: "nowrap",
                  }}
                >
                  {outputValues[10] === 0 ? (
                    <CircularProgress />
                  ) : (
                    outputValues[10] + " %"
                  )}
                </Typography>
              </Item>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <Item>
            <Typography noWrap sx={{ fontSize: 20 }}>
              Einstrahlung
            </Typography>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", mt: 4, mb: 2, whiteSpace: "nowrap" }}
            >
              {outputValues[11] === 0 ? (
                <CircularProgress />
              ) : (
                outputValues[11] + " kWh/m²"
              )}
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
